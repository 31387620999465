import request from '@/core/services/api.services.request'

export function getAllSites() {
  return request({
    url: '/website/list',
    method: 'get',
  })
}

export function getAllActiveSites() {
  return request({
    url: '/website/active',
    method: 'get',
  })
}

export function getWebsiteDetails(website_id) {
  return request({
    url: '/website/view/'+website_id,
    method: 'get'
  })
}

export function deleteWebsite(website_id) {
  return request({
    url: '/website/delete/'+website_id,
    method: 'delete'
  })
}

export function changeWebsiteStatus(website_id) {
  return request({
    url: '/website/change/status/' + website_id + '/' + status,
    method: 'post'
  })
}